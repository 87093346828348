<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">เรียกดูรายงานลูกค้า : {{this.totle}} รายการ</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <button class="btn btn-sm btn-success ml-2" @click.prevent="printResults()"  style=""><i class="fas fa-print fa-fw"></i>Print</button>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="clearData()"  style=""><i class="fas fa-sync-alt fa-fw"></i></button>
          </div>
        </div>
      </div>
<!--      <div class="card-body">-->
<!--        {{$v}}-->
<!--      </div>-->
      <div class="card-body">
        <form ref="form" @submit.prevent="searchCustomer" autocomplete="off">
          <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>วันทำเริ่มค้นหา</label>
            </div>
            <input
                v-model="form.dateForm"
                type="date"
                class="form-control"
            />
          </div>
            <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>วันสิ้นสุด</label>
              </div>
              <input
                  v-model="form.dateTo"
                  type="date"
                  class="form-control"
              />
            </div>
            <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>ชื่อ</label>
              </div>
              <input
                  v-model="form.custName"
                  type="text"
                  class="form-control"
              />
            </div>
            <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>นามสกุล</label>
              </div>
              <input
                  v-model="form.custSurname"
                  type="text"
                  class="form-control"
              />
            </div>
            <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>รับข่าวสาร</label>
              </div>
              <select v-model="form.custNews" class="form-control">
                <option  :value="'True'">ต้องการข่าวสาร</option>
                <option  :value="'False'">ไม่ต้องการข่าวสาร</option>
              </select>
            </div>
            <div class="form-group col-md-2 col-sm-1">
              <div class="col">
                <label>ค้นหา</label>
              </div>
              <button type="submit" class="btn btn-block btn-primary">ค้นหา</button>
            </div>
        </div>
        </form>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="card-body table-responsive p-0" style="height: 560px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
<!--                <th scope="col">#</th>-->
                <th scope="col" style="background-color: rgb(197,196,196)">All <input type="checkbox" @click="selectAll" v-model="allSelected" ></th>
                <th v-for="ths in th" :key="ths" style="background-color: rgb(197,196,196)">
                  {{ths}}
                </th>
              </tr>
              </thead>
                <tbody>
                <tr v-for="(datas) in data.data" :key="datas.id">
                  <td style="font-size: 12px">
                    <input type="checkbox" v-model="custId" class="" :value="datas.custId" @click="select">
                  </td>
                  <td style="width: 7%;font-size: 12px">{{datas.custNo }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.custTitle === 'ไม่ระบุ' ? '' : datas.custTitle}}  {{datas.custName}} {{datas.custSurname}}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.company.comNameTh }}</td>
                  <td style="font-size: 12px">{{datas.custAddress }}</td>
                  <td style="font-size: 12px">{{datas.custSoi }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.custRoad }}</td>
                  <td style="font-size: 12px">{{datas.custDistricts }}</td>
                  <td style="font-size: 12px">{{datas.custAmphurs }}</td>
                  <td style="font-size: 12px">{{datas.custProvince }}</td>
                  <td style="font-size: 12px">{{datas.custZipcode }}</td>
                  <td style="font-size: 12px">{{datas.custTel }}</td>
                  <td style="font-size: 12px">{{datas.custMobile }}</td>
                  <td style="font-size: 12px">{{datas.custTarget }}</td>
                  <td style="font-size: 12px">{{datas.user.name }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.custRecordData | myDateThs}}</td>
                </tr>
                <tr v-show="!this.data"><td colspan="19" class="text-center">ไม่มีรายการ</td></tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>
    <!-- /.card -->

  </section>
</template>

<script>
import axios from 'axios';
import {Form} from "vform";
export default {
  name: 'customerreport',
  data(){
    return{
      data:'',
      th:['รหัสลูกค้า','ชื่อ-นามสกุล','สาขา','เลขที่','ตรอก/ซอย','ถนน','ตำบล','อำเภอ','จังหวัด','รหัส','เบอร์โทร','มือถือ','กลุ่ม','สร้างโดย','วันที่สร้าง'],
      Index: '',
      company: '',
      search: '',
      allSelected: false,
      selected: [],
      custId:[],
      totle:'',



      form : new Form ({
        dateForm:'',
        dateTo:'',
        custName : '',
        custSurname : '',
        custNews : 'True',
      })


    }
  },

  mounted() {

  },
  methods:{

    async getResults(){
    },

    clearData(){
      this.data = ''
      this.custId = [];
      this.allSelected = false;
    },


    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },

    async searchCustomer(){
      if (this.form.dateForm || this.form.dateTo || this.form.custName || this.form.custSurname) {
        await this.form.post('api/customer/customerReportSearch')
          .then( async response => {
              if (response.data.success === true ){
                this.data = response.data
                this.totle = this.data.data.length
                this.toast('Your work has been search')
              }
            }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
              }
            });

      }else {
        this.$swal('something wrong', 'กรุณาใส่ข้อมูลที่ต้องการค้นหา', 'error');
      }
    },

    selectAll: function() {
      this.custId = [];
      this.allSelected =!this.allSelected

      if (this.allSelected ) {
        for (let customer in this.data.data) {
          this.custId.push(this.data.data[customer].custId);
        }

      }else {
        this.custId = [];
      }
    },

    select: function() {
      this.allSelected = false;
    },

    async printResults(){
      if (this.custId.length > 0){
        const options = {
          responseType: 'arraybuffer',
        };
        let data = {
          custId : this.custId
        }
       await axios.post('api/customer/customerReport',data,options)
            .then((res) => {
              let blob = new Blob([res.data], {type: "application/pdf"});
              let win = window.open('', '_blank');
              let URL = window.URL || window.webkitURL;
              let dataUrl = URL.createObjectURL(blob);
              win.location = dataUrl;
            })
      }else {
        this.$swal('something wrong', 'กรุณาใส่เลือกรายการต้องการค้นหา', 'error');
      }

    },

  }

}
</script>
